import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { useParams } from 'react-router-dom'
import "../css/ShirtItem.scss"
import { NavLink, useNavigate   } from 'react-router-dom'
import ShirtError from '../Components/ShirtError'



import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ShirtItem() {
    let {id} = useParams();
    const [item, setItem] = useState(null)

    useEffect(() => {
      supabase
      .from("shirts")
      .select('*')
      .eq('id', id)
      .then(result => {
          if (result.error) {
              console.error(result.error)
          } else {
              setItem(prevItem => result.data[0]);
              console.log(result.data[0]);
          }
      });
  }, [id]);
    const picpath = "https://qdlngfzrpuvpoorbmirs.supabase.co/storage/v1/object/public/shirtimages/be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/"
    
    let navigate  = useNavigate();
    if (!item) {
        return (
          <div className='pageNotFound'>
            <ShirtError/>
          </div>
        );
      }

      

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <><div className='backToLeagueWrapper' onClick={() => navigate(-1)}><NavLink className='backToLeague'>Tilbage</NavLink></div>
    <div className='shirtItemHolder'>
        <div className='shirtImage'>
          <Slider className='slider' {...settings}>
            <div>   
              <img alt={"this is a picture of the front of " + item.name + " " + item.season + " " + item.type + "shirt"} src={picpath + item.front_image_url}/>
            </div>
            <div>
              <img alt={"this is a picture of the back of " + item.name + " " + item.season + " " + item.type + "shirt"} src={picpath + item.back_image_url}/>
            </div>
          </Slider>
        </div>
        <article>
          <h1>{item.name}</h1>
          
          {/* <p>Det her er en {item.name} {item.type} trøje fra sæsonen {item.season}</p> */}
          <NavLink className='shirtLeague' to={"../../Shirts/" + item.league}>{item.league}</NavLink>
          <p>{item.type} {item.season}</p>
          <p>{item.description}</p>
        </article>
    </div></>
  )
}

export default ShirtItem