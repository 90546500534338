import React from 'react'
import "../css/About.scss"
import picOfMe from "../img/1684158846393.jpg"
function About() {

    
var birthDate = new Date(2003, 3, 14, 0, 0, 0, 0);

var currentDate = new Date();

var age = currentDate.getFullYear() - birthDate.getFullYear();

var month = currentDate.getMonth() - birthDate.getMonth();

var day = currentDate.getDate() - birthDate.getDate();

if ( month < 0 || (month === 0 && day < 0) )
{
  age--;
}

  return (
    <div className='About'>
      <h1>Om Mig</h1>
      <div className='AboutHolder'>
        <div  className='AboutImage'>
          <img alt="this is me" src={picOfMe}/>
        </div>
        <div className='AboutText'>
          <p>Hej jeg er Oliver, jeg er {age} år gammel, det her er min fodboldtrøje samling. Jeg har lavede denne hjemmeside da jeg er en stor fan af fodbold, og specielt fodboldtrøjer.
          Jeg er også uddannet webudvikler, så jeg valgte at lave denne hjemmeside for at holde styr på min trøjesamling</p>
          <p>Ligesom alle andre fodboldfans, har jeg selvfølgelig også nogle yndlings klubber. Min favorit klub er Randers FC. Jeg er selv fra en by lidt udenfor Randers og hele min familie er Randers fans så det var rimelig naturligt at jeg blev fan af dem. Jeg er også stor Borussia Dortmund fan. 
            Det blev jeg for alvor da jeg kom ned og se deres stadion efter en familieferie i 2017. Jeg havde hørt om deres stadion og fans og ønskede at komme ned og se stadionet selv. Lige siden har jeg været kæmpe fan af klubben og fansene, jeg har også været nede og se et par kampe siden. 
            Jeg kan også godt lide Arsenal F.C. Min onkel har været arsenal fan så længe jeg kan huske og det er også grunden til at jeg holder meget øje med dem og godt kan lide at se deres kampe.</p>
          <p>Du er velkommen til at tjekke min samling ud!</p>
        </div>
        
      </div>
      
    </div>
  )
}

export default About