import React from 'react'
import shirtsIMG from "../img/Shirts.png"
import leaguesIMG from "../img/leagues.png"
import { NavLink } from 'react-router-dom'
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import { Auth } from '@supabase/auth-ui-react'
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'
import "../css/Admin.scss"


function Admin() {

  const user = useUser();
  const supabase = useSupabaseClient();

// console.log(email)

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    console.log(error)
  }
//   console.log(user.email)

  return (
    <div>

{ user === null ?
            <>
            {/* 
                <Form>
            <Form.Group className="mb-3" style={{maxWidth: "500px"}}>
              <Form.Label>Enter an email to sign in with a Supabase Magic Link</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={() => magicLinkLogin()}>
              Get Magic Link
            </Button>
          </Form> */}

          <div className='loginHolder'>
            <h3>Login</h3>
            <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }}/>
          </div>


                {/* <form>
                <div>
                <label>Enter an email to sign in with a Supabase Magic Link</label>
                <input
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                </div>
                <button variant="primary" onClick={() => magicLinkLogin()}>
                Get Magic Link
                </button>
            </form> */}
            </> 
            : 
            <>
                logged in as <b>{user.email}</b>
                <br/>
          <button className='logoutButton' onClick={() => signOut()}>Sign Out</button>
          <div className='editsHolder'>
              <NavLink className="editLinks" to="../AdminShirt">
                <section>
                  <img alt="go in to shirt admin" src={shirtsIMG}/>
                  <h2>Edit Shirts</h2>
                </section>
              </NavLink>

              <NavLink className="editLinks" to="../AdminLeague">
              <section>
                  <img alt="go in to league admin" src={leaguesIMG}/>
                  <h2>Edit Leagues</h2>
              </section>
              </NavLink>
            </div>
            </>
}
      
    </div>
  )
}

export default Admin