import React, {useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { supabase } from '../supabaseClient'
import ShirtCard from '../Components/ShirtCard';
import "../css/AllShirts.scss"


function AllShirts() {

    const [ shirts, setShirts] = useState([]);
    const [ orderBy, setOrderBy] = useState("name")
    const [ascendingTF, setAscendingTF] = useState(true)

          useEffect(() => {
          
          const optionId = document.getElementById("orderSelector").value
          if(optionId === "seasonNewToOld"){
            setAscendingTF(false)
            setOrderBy("season")
          }else{
            setAscendingTF(true)
          }
          
    async function getShirts() {
        try {
          const { data, error } = await supabase
            .from("shirts")
            .select("*")
            .order(orderBy, {ascending: ascendingTF})
          if (error) throw error;
          if (data != null) {
            setShirts(data);
          }
        } catch (error) {
          console.log(error.message);
        }
      }
      getShirts();
    }, [orderBy, ascendingTF])
  return (
    <div>
      <div className='goBackWrapper'>
          <NavLink className="goBackButton" to="../Leagues">
            Tilbage
          </NavLink>
          <h1 className='shirtsLeagueH1'>Alle Trøjer</h1>
        </div>
      <div className='sortByWrapper'>
      <div className='sortByHolder'>
        <p>Sorter efter</p>
      <select id="orderSelector" onChange={(e) => setOrderBy(e.target.value) & setAscendingTF(e.target.value)}>
        <option  value="name">Name</option>{/* true */}
        <option  value="league">League</option>{/* true */}
        <option  value="seasonNewToOld">Season (Nyeste - Ældste)</option>{/* false */}
        <option  value="season">Season (Ældste - Nyeste)</option>{/* true */}
      </select>
      </div>
      </div>
        <div className='cardsHolder'>
            {shirts.map((shirt) => (
                <div key={shirt.id} className='ShirtCard'>
                    <ShirtCard shirt={shirt}/>
                </div>
            ))}</div>
    </div>
  )
}

export default AllShirts