import React from 'react'
import "../css/Footer.scss"
function Footer() {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Olivers fodboldtrøjer, Inc. All rights reserved.</p>
    </footer>
  )
}

export default Footer