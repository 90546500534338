import React, {useState, useEffect} from 'react'
import { supabase } from '../supabaseClient'
import "../css/ShirtCounter.scss"

function ShirtCounter() {

    const [objectCount, setObjectCount] = useState(0);

    useEffect(() => {
      getAllShirts();
    }, []);

    const getAllShirts = async () => {
      try {
        const { count } = await supabase
          .from("shirts")
          .select('*', {count: 'exact'})

          setObjectCount(count);
      } catch (error) {
        console.log(error.message);
      }
    }



    const [count, setCount] = useState(0);

useEffect(() => {
    let animation = null;
    if (count < objectCount) {
      animation = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 10);
    }
    return () => {
      clearInterval(animation);
    };
  }, [count, objectCount]);

  return (
    <div className='ShirtCounter'>
        <h2>Jeg har 
         <span> {count} </span>
          trøjer</h2>
    </div>
  )
}

export default ShirtCounter