import React from 'react'
import { supabase } from '../supabaseClient';
import {Button} from 'react-bootstrap';

function LeagueAdminCard(props) {
    const league = props.league;

    async function deleteProduct(leagueImg) {
        try {
            const { error } = await supabase
                .from("leagues")
                .delete()
                .eq("id", league.id)
            
            if (error) throw error;
            window.location.reload();
        } catch (error) {
            alert(error.message);
        }
        await supabase.storage.from('leagueimages').remove(["be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/" + leagueImg])

    }
    const picpath = "https://qdlngfzrpuvpoorbmirs.supabase.co/storage/v1/object/public/leagueimages/be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/"
    return (
    <div>
        <h2>{league.name}</h2>
        <div className='imageHolder'>
        <img alt={"this is a picture of " + league.name} src={picpath + league.leagueImg}/>
        </div>
        <br/>
        <Button variant="danger" onClick={() => deleteProduct(league.leagueImg)}>Delete League</Button>
    </div>
  )
}

export default LeagueAdminCard