import React, {useState, useEffect} from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ShirtCard from '../Components/ShirtCard';
import { supabase } from '../supabaseClient';
import "../css/shirtCards.scss";


function Shirts() {
    let {id} = useParams();
    const [ shirts, setShirts] = useState([]);
    const [ orderBy, setOrderBy] = useState("name")
    const [ascendingTF, setAscendingTF] = useState(true)

        useEffect(() => {
            
          const optionId = document.getElementById("orderSelector").value
          if(optionId === "seasonNewToOld"){
            setAscendingTF(false)
            setOrderBy("season")
          }else{
            setAscendingTF(true)
          }
        
    async function getShirts() {
        try {
          const { data, error } = await supabase
            .from("shirts")
            .select("*")
            .order(orderBy, {ascending: ascendingTF})
          if (error) throw error;
          if (data != null) {
            setShirts(data); // [product1,product2,product3]
          }
        } catch (error) {
          console.log(error.message);
        }
      }
      getShirts();
    }, [orderBy, ascendingTF ])
  return (
    <div>
        <div className='goBackWrapper'>
          <NavLink className="goBackButton" to="../Leagues">
            Tilbage
          </NavLink>
          <h1 className='shirtsLeagueH1'>{id}</h1>
        </div>
        <div className='sortByWrapper'>
      <div className='sortByHolder'>
        <p>Sorter efter</p>
      <select  id="orderSelector"  onChange={(e) => setOrderBy(e.target.value) & setAscendingTF(e.target.value)}>
        <option value="name">Navn</option>
        <option value="seasonNewToOld">Sæson (Nyeste - Ældste)</option>
        <option value="season">Sæson (Ældste - Nyeste)</option>
      </select>
      </div>
      </div>
        <div className='cardsHolder'>
            
          {shirts && shirts.map(shirt => {
          if(shirt.league === id){
              return(
              <div  className='ShirtCard'>
                  <ShirtCard shirt={shirt}/>
              </div>
              )}
              else {
                return null;
              }
          })}
        </div>
  </div>
  )
}

export default Shirts