import React, {useState, useEffect} from 'react'
import { useUser } from "@supabase/auth-helpers-react";
import { supabase } from '../supabaseClient'
import ShirtAdminCard from '../Components/ShirtAdminCard';
import "../css/shirtAdmin.scss"
import Resizer from 'react-image-file-resizer';

function AdminShirt() {
  const user = useUser();

  const [ shirts, setShirts] = useState([]);


        useEffect(() => {
            getShirts();
          }, [])
        
    async function getShirts() {
        try {
          const { data, error } = await supabase
            .from("shirts")
            .select("*")
            .order("name")
          if (error) throw error;
          if (data != null) {
            setShirts(data);
          }
        } catch (error) {
          alert(error.message);
        }
      }


      const [ leagues, setLeagues] = useState([]);
        useEffect(() => {
            getLeagues();
          }, [])
        
    async function getLeagues() {
        try {
          const { data, error } = await supabase
            .from("leagues")
            .select("*")
            .order("name")
          if (error) throw error;
          if (data != null) {
            setLeagues(data);
          }
        } catch (error) {
          alert(error.message);
        }
      }

      const [ leagueName, setLeagueName ] = useState("");
      const [ description, setDescription ] = useState("");
      const [ type, setType ] = useState("");
      const [ season, setSeason ] = useState("");
      const [ mw, setMw ] = useState("");
      const [ league, setLeague ] = useState("");


      // const [compressedFile, setCompressedFile] = useState(null);

        async function uploadImage(e) {
            let file = e.target.files[0];

            Resizer.imageFileResizer(
              file,
              800,
              800,
              'JPEG',
              80,
              0,
              async (compressedResult) => {
                try {
                  const blob = await fetch(compressedResult).then((r) => r.blob());
          
                  const formData = new FormData();
                  formData.append('file', blob, file.name);
          
                  await supabase.storage
                    .from('shirtimages')
                    .upload("be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/" + file.name, formData);
          
                  // Rest of your code, if any
                } catch (error) {
                  console.error('Error uploading image:', error);
                }
              },
              'base64'
            );
          }

          async function createShirt() {
            let fileInputFront = document.getElementById("shirtImgFront");
            let fileNameFront = fileInputFront.files[0].name;

            let fileInputBack = document.getElementById("shirtImgBack");
            let fileNameBack = fileInputBack.files[0].name;
            try {
              const { error } = await supabase
                .from("shirts")
                .insert({
                  name: leagueName,
                  description: description,
                  type: type,
                  season: season,
                  mw: mw,
                  league: league,
                  front_image_url: fileNameFront,
                  back_image_url: fileNameBack
                })
                .single()
                
              if (error) throw error;
              window.location.reload();
            } catch (error) {
              alert(error.message);
            }
          }








          const shirtFrontInput = document.getElementById("shirtImgFront");
          const shirtFrontNameContainer = document.getElementById("shirtFrontDisplay");
  
          if(shirtFrontInput === null){
          }else{
            shirtFrontInput.addEventListener("change", () => {
            const shirtFrontName = shirtFrontInput.value.split("\\").pop();
            shirtFrontNameContainer.textContent = shirtFrontName;
          })}






          const shirtBackInput = document.getElementById("shirtImgBack");
          const shirtBackNameContainer = document.getElementById("shirtBackDisplay");
  
          if(shirtBackInput === null){
          }else{
            shirtBackInput.addEventListener("change", () => {
            const shirtBackName = shirtBackInput.value.split("\\").pop();
            shirtBackNameContainer.textContent = shirtBackName;
          })}





  return (
    <div>
{ user === null ?
            <>
                <h1>Not logged in</h1>
                <h3>Hmmmm... det ser ikke ud til at du er logget ind ( ‾ʖ̫‾)</h3>
                
            </> 
            : 
            <>
                logged in as <b>{user.email}</b>
                <br/>
                <div className="createShirt">
                <h3>Create Shirt</h3>
                <label>Club:</label>
                <br/>
                <input
                  type="text"
                  id="name"
                  onChange={(e) => setLeagueName(e.target.value)}
                />
                <br/>

                <label>Shirt Type:</label>
                <br/>
                <select
                  type="text"
                  id="type"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>Select the type</option>
                  <option value="Home">Home</option>
                  <option value="Away">Away</option>
                  <option value="Third">Third</option>
                  <option value="Goalkeeper">Goalkeeper</option>
                  <option value="Training">Training</option>
                  <option value="Speciel">Speciel</option>
                </select>
                <br/>

                <label>Season:</label>
                <br/>
                <input
                  type="text"
                  id="season"
                  onChange={(e) => setSeason(e.target.value)}
                />
                <br/>

                
                <p>Pictures of the shirt:</p>
                <input type="file" id="shirtImgFront" accept="image/png, image/jpeg" onChange={(e) => uploadImage(e)}/>
                <label for="shirtImgFront" className='btn-2'>Upload Shirt Front:</label>
                <div id="shirtFrontDisplay"></div>
                
                <input type="file" id="shirtImgBack" accept="image/png, image/jpeg" onChange={(e) => uploadImage(e)}/>
                <label for="shirtImgBack" className='btn-2'>Upload Shirt Back:</label>
                <div id="shirtBackDisplay"></div>
                <br/>

                <label>Shirt Description:</label>
                <br/>
                <textarea
                  type="text"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br/>

                <label>Matchworn:</label>
                <br/>
                <select
                  type="text"
                  id="mw"
                  onChange={(e) => setMw(e.target.value)}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
                <br/>

                <label>League:</label>
                <br/>
                <select
                  type="text"
                  id="league"
                  onChange={(e) => setLeague(e.target.value)}
                >
                  <option>Select the league</option>
                  {leagues.map((league) => (
                    <option value={league.name}>{league.name}</option>
                  ))}
                </select>
                <br/>

                
                <button onClick={() => createShirt()}>Create Shirt</button>
                </div>
                <br/>
                <h1>Delete a shirt</h1>
                <div className='shirtAdminCardsWrap'>
                {shirts.map((shirt) => (
                <div className='shirtAdminCardHolder'>
                    <ShirtAdminCard shirt={shirt}/>
                </div>
            ))}</div>
            </>}
    </div>
  )
}

export default AdminShirt