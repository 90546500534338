import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from '@supabase/supabase-js';


const supabase = createClient("https://qdlngfzrpuvpoorbmirs.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFkbG5nZnpycHV2cG9vcmJtaXJzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODAwODIwNDYsImV4cCI6MTk5NTY1ODA0Nn0.dGak0MuPvC6Q-i394qniibAj0LYnaYjJdjAnly7GI4k");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabase}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </SessionContextProvider>
  </React.StrictMode>
);