import React, { useState, useEffect } from 'react'
import "../css/ShirtItem.scss"
import ShirtErrorMessage from './ShirtErrorMessage';

function ShirtError() {
    const [showComponent, setShowComponent] = useState(false);
    
    useEffect(() => {
        setInterval(() => {
            setShowComponent(true)
        }, 3000);
    }, [])
  return (
    <>
    {showComponent && <ShirtErrorMessage/>}
    </>
  )
}

export default ShirtError