import React from 'react'
import { Route, Routes } from "react-router-dom";

import Home from '../Pages/Home';
import Admin from '../Pages/Admin';
import AdminShirt from '../Pages/AdminShirt';
import AdminLeague from '../Pages/AdminLeague';
import Leagues from '../Pages/Leagues';
import Shirts from '../Pages/Shirts';
import ShirtItem from '../Pages/ShirtItem';
import About from '../Pages/About';
import AllShirts from '../Pages/AllShirts';
import PageNotFound from '../Pages/PageNotFound';

import "../css/Body.scss"
function Body() {
  <link href="path/to/lightbox.css" rel="stylesheet" />
  return (
    <main>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/admin" element={<Admin/>}/>
            <Route path="/adminShirt" element={<AdminShirt/>}/>
            <Route path="/adminLeague" element={<AdminLeague/>}/>
            <Route path="/Leagues" element={<Leagues/>}/>
            <Route path="/Shirts/:id" element={<Shirts/>}/>
            <Route path="/Shirt/:id" element={<ShirtItem/>}/>
            <Route path="/About" element={<About/>}/>
            <Route path="/AllShirts" element={<AllShirts/>}/>
            <Route path="/*" element={<PageNotFound/>}/>
            <Route path="/Shirt/*" element={<PageNotFound/>}/>
        </Routes>
    </main>
  )
}
<script src="path/to/lightbox.js"></script>
export default Body