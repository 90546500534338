import React, {useState, useEffect} from 'react'
import { useUser } from "@supabase/auth-helpers-react";
import { supabase } from '../supabaseClient'
import LeagueAdminCard from '../Components/LeagueAdminCard';
import "../css/shirtAdmin.scss"

function AdminLeague() {
    const user = useUser();
    



    const [ leagues, setLeagues] = useState([]);
  
  
          useEffect(() => {
            getLeagues();
            }, [])
          
      async function getLeagues() {
          try {
            const { data, error } = await supabase
              .from("leagues")
              .select("*")
            if (error) throw error;
            if (data != null) {
                setLeagues(data); // [product1,product2,product3]
            }
          } catch (error) {
            alert(error.message);
          }
        }
        // console.log(leagues)








        const leagueImgInput = document.getElementById("leagueImg");
        const leagueImgNameContainer = document.getElementById("fileNameDisplay");

        if(leagueImgInput === null){
        }else{
        leagueImgInput.addEventListener("change", () => {
          const leagueImgName = leagueImgInput.value.split("\\").pop();
          leagueImgNameContainer.textContent = leagueImgName;
        })}




        const [ leagueName, setLeagueName ] = useState("");

        async function uploadImage(e) {
            let file = e.target.files[0];
            await supabase
            .storage
            .from('leagueimages')
            .upload("be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/" + file.name, file);

          }

          async function createLeague() {
            let fileInput = document.getElementById("leagueImg");
            let fileName = fileInput.files[0].name;
            try {
              const { error } = await supabase
                .from("leagues")
                .insert({
                  name: leagueName,
                  leagueImg: fileName
                })
                .single()
                
              if (error) throw error;
              window.location.reload();
            } catch (error) {
              alert(error.message);
            }
          }
  return (
    <div>
        { user === null ?
            <>
                <h1>Not logged in</h1>
                <h3>Hmmmm... det ser ikke ud til at du er logget ind ( ‾ʖ̫‾)</h3>
            </> 
            : 
            <>
                logged in as <b>{user.email}</b>
                <br/>


            <div className="createLeague">
            <h3>Create League</h3>
            <label>League Name</label>
            <br/>
            <input
              type="text"
              id="name"
              onChange={(e) => setLeagueName(e.target.value)}
            />
            <br/>

            
            <input type="file" id="leagueImg" accept="image/png, image/jpeg" onChange={(e) => uploadImage(e)}/>
            <label for="leagueImg" className='btn-2'>Upload Image</label>
            <div id="fileNameDisplay"></div>
            <br/>
            <button onClick={() => createLeague()}>Create League</button>
            </div>
            <br/>
                <div className='shirtAdminCardsWrap'>
                {leagues.map((league) => (
                <div className='shirtAdminCardHolder'>
                    <LeagueAdminCard league={league}/>
                </div>
            ))}</div>
            </>
        }
    </div>
  )
}

export default AdminLeague