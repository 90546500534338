import React from 'react'
import {Card} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function ShirtCard(props) {
    const shirt = props.shirt;

    const picpath = "https://qdlngfzrpuvpoorbmirs.supabase.co/storage/v1/object/public/shirtimages/be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/"
    return (
    <NavLink to={"/Shirt/" + shirt.id}>
      <Card>
      
            <div className='cardInfo'>
              <div className='imageHolder'>
                {shirt.mw === "Yes" ?
                  <> <p className="matchworn">Matchworn</p> </>
                  :
                  <> </>
                }
              
              <img alt={"this is a picture of " + shirt.name + " " + shirt.season + " " + shirt.type + "shirt"} src={picpath + shirt.front_image_url}/>
              </div>
                <article>
                  <h2>{shirt.name}</h2>
                  <p className='league'>{shirt.league}</p>
                  <p>{shirt.type} {shirt.season}</p>
                </article>
            </div>
      
      </Card>
    </NavLink>
  )}

export default ShirtCard