import React from 'react'
import errorpic from "../img/sunbathing-dogs.jpg"
import "../css/Error.scss"
import { NavLink } from 'react-router-dom'

function PageNotFound() {
  return (
    <div className='pageNotFound'>
        <h1>Whoops!</h1>
        <h2>404 Page Not Found</h2>
        <img src={errorpic} alt="Dogs sunbathing"/>
        {/* <p>Looks like this page went on vacation</p> */}
        <p>Det ser ud til at denne side er gået på ferie</p>
        <p>↓ Skal vi ikke lade den nyde sin ferie ↓</p>
        <NavLink to="../">
          <p className='errorGoBackButton'>Gå tilbage til forsiden</p>
        </NavLink>
    </div>
  )
}

export default PageNotFound