import React, {useState, useEffect} from 'react'
import { supabase } from '../supabaseClient'
import LeagueCard from '../Components/LeagueCard';
import { NavLink } from 'react-router-dom'
import "../css/Leagues.scss"
import AllShirts from "../img/allShirts.jpg"

function Leagues() {
    const [ leagues, setLeagues] = useState([]);


        useEffect(() => {
            getLeagues();
          }, [])
        
    async function getLeagues() {
        try {
          const { data, error } = await supabase
            .from("leagues")
            .select("*")
            .order("name")
          if (error) throw error;
          if (data != null) {
            setLeagues(data); // [product1,product2,product3]
          }
        } catch (error) {
          console.log(error.message);
        }
      }

  return (
    <>
    <h2 className='ligaH2'>Vælg en liga</h2>
        <div className='LeaguesCardsWrap'>
          <div className='LeaguesCardHolder'>
              <NavLink to="/allshirts">
                <h2>Alle Trøjer</h2>
                <img alt="this is all shirts" src={AllShirts}/>
              </NavLink>
            </div>
            {leagues.map((league) => (
                    <>
                        <LeagueCard league={league}/>
                    </>
            ))}
            
        </div>
    </>
  )
}

export default Leagues