import React, {useState} from "react";
import { NavLink } from 'react-router-dom'
import { slide as Menu } from "react-burger-menu";
import { useUser } from "@supabase/auth-helpers-react";

import "../css/Header/Menu.scss"

const Sidebar = () => {
  const user = useUser();

    const [isOpen, setOpen] = useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

    return (
        <div>
            <div className="burgermenu">
    <Menu
    isOpen={isOpen}
    onOpen={handleIsOpen}
    onClose={handleIsOpen}
    >
    <div className="menuknapper">
    <ul>
        <li>
            <NavLink className="menu-item" onClick={closeSideBar} to="/">Hjem</NavLink>
        </li>
        <br/>
        <li>
            <NavLink className="menu-item" onClick={closeSideBar} to="Leagues">Trøjer</NavLink>
        </li>
        <br/>
        <li>
            <NavLink className="menu-item" onClick={closeSideBar} to="About">Om Mig</NavLink>
        </li>
        <br/>
        {user === null ?
        <>
        <li>
            <NavLink className="menu-item adminNavLink" onClick={closeSideBar} to="admin">Login</NavLink>
        </li>
        </>
        :
        <>
        <li>
            <NavLink className="menu-item adminNavLink" onClick={closeSideBar} to="admin">Admin</NavLink>
        </li>
        </>  
      }
        
        <br/>
    </ul>
    </div>
    </Menu></div>
        </div>
    )
}

export default Sidebar
