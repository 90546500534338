import React from 'react'
import { NavLink } from 'react-router-dom'


function LeagueCard(props) {
    const league = props.league;

    const picpath = "https://qdlngfzrpuvpoorbmirs.supabase.co/storage/v1/object/public/leagueimages/be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/"
  
    return (
      <NavLink className="LeaguesCardHolder" to={"/Shirts/" + league.name}>
        <div>
        <h2>{league.name}</h2>
        <img alt={"this is a picture of " + league.name} src={picpath + league.leagueImg}/>
        <br/>
        </div>
      </NavLink>
  )
}

export default LeagueCard