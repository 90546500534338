import React, {useState, useEffect} from 'react'
import ShirtCard from '../Components/ShirtCard';
import { supabase } from '../supabaseClient'
import "../css/shirtCards.scss"
import "../css/Home.scss"
import shirtsIMG from "../img/Shirts.png"
import ShirtCounter from '../Components/ShirtCounter';

function Home() {

        const [ shirts, setShirts] = useState([]);

        useEffect(() => {
            getShirts();
          }, [])
        
    async function getShirts() {
        try {
          const { data, error } = await supabase
            .from("shirts")
            .select("*")
            .order("id", {ascending: false})
            .limit(3)
          if (error) throw error;
          if (data != null) {
            setShirts(data); // [product1,product2,product3]
          }
        } catch (error) {
          console.log(error.message);
        }
      }
  return (
    <>
        {/* {images.map((image) => (
                    <img variant="top" src={picpath + image.name} alt={image.name} />
            ))} */}
            <section className='welcome'>
              <article className='welcome-Message'>
                <h2>Velkommen til</h2>
                <h1>Min Fodboldtrøje samling</h1>
                <p>Her kan du se alle mine fodboldtrøjer og holde dig opdateret når jeg får nye!</p>
                <ShirtCounter/>
              </article>
              <div className='welcome-pic'>
                <img alt="welcome to my shirt collection" src={shirtsIMG}/>
              </div>
            </section>
            {/* <h2>Jeg har {count} trøjer</h2> */}
            
            <h2 className='newShirts'>Mine nyeste trøjer!</h2>
            <div className='cardsHolder'>
            {shirts.map((shirt) => (
                <div className='ShirtCard'>
                    <ShirtCard shirt={shirt}/>
                </div>
            ))}</div>
    </>
  )
}

export default Home