import React from 'react'
import { supabase } from '../supabaseClient';
import {Button} from 'react-bootstrap';

function ShirtAdminCard(props) {
    const shirt = props.shirt;

    async function deleteProduct(front_image_url, back_image_url) {
        try {
            const { error } = await supabase
                .from("shirts")
                .delete()
                .eq("id", shirt.id)
            
            if (error) throw error;
            window.location.reload();
        } catch (error) {
            alert(error.message);
        }
        await supabase.storage.from('shirtimages').remove(["be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/" + front_image_url ])
        await supabase.storage.from('shirtimages').remove(["be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/" + back_image_url ])
    }
    const picpath = "https://qdlngfzrpuvpoorbmirs.supabase.co/storage/v1/object/public/shirtimages/be5bd560-e4e1-4c12-8230-b4b2a2ccbc3a/"
    // console.log(shirt.front_image_url)
  return (
    <div>
        <h2>{shirt.name}</h2>
        <div className='imageHolder'>
            <img alt={"this is a picture of " + shirt.name + " " + shirt.season + " " + shirt.type + "shirt"} src={picpath + shirt.front_image_url}/>
        </div>
        <br/>
        <Button variant="danger" onClick={() => deleteProduct(shirt.front_image_url, shirt.back_image_url)}>Delete Shirt</Button>
    </div>
  )
}

export default ShirtAdminCard