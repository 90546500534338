import React from 'react'
import "../css/Header/Header.scss"
// import Logo from "../img/MFSLogo.png"
import Logo from "../img/Myproject-1.png"
import { NavLink } from 'react-router-dom'
// import Sidebar from "./HeaderMenu"
import { useUser } from "@supabase/auth-helpers-react";
import Sidebar from "./HeaderMenu.jsx"



function Header() {
  const user = useUser();

  return (
    <header>
            <div className="wrapper">
    <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />
    {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} /> */}
    <nav>
      <div className="content">
      <div className="logo" id="logo"><NavLink className="header__a" to="/"><img src={Logo} alt="logo of the website"/></NavLink></div>
        <ul className="links">
          <li><NavLink className="header__a" to="/">Hjem</NavLink></li>
          <li><NavLink className="header__a" to="Leagues">Trøjer</NavLink></li>
          <li><NavLink className="header__a" to="About">Om Mig</NavLink></li>
          {/* <li><NavLink className="header__a" to="Blahblah">Blah blah</NavLink></li> */}
          {user === null ? 
          <>
          <li id='adminNavLink'><NavLink className="header__a" to="admin">Login</NavLink></li>
          </>
          :
          <>
          <li id='adminNavLink'><NavLink className="header__a" to="admin">Admin</NavLink></li>
          </>}
          
        </ul>
      </div>
    </nav>

    

    
  </div>
        </header>
        
  )
}

export default Header